'use strict'

/**
 * File to define a custom get info table columns
 *
 * @author Oscar Paredes
 * @since 18/02/2020 10:10 AM
 * @version 1.0.0
 */

export default {
  computed: {
    /**
     * Function that translate the attributes of the columns.
     * The attributes are {title}
     *
     * @author Oscar Paredes
     * @since 18/02/2020 10:10 AM
     * @version 1.0.0
     */
    $_tableHeaderMixin_generate () {
      return this.columnOptions.map((column) => {
        if (column.text) {
          column.text = this.$t(column.text)
        }
        return column
      })
    }
  }
}
