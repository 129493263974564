<template>
  <v-flex
    xs12
  >
    <v-data-table
      ref="dataTable"
      v-model="selected"
      :headers="tableFields"
      :items="data"
      :options.sync="pagination"
      :server-items-length="total"
      :loading="loading"
      :headers-length="headersLength"
      :items-per-page="itemsPerPage"
      :footer-props="{itemsPerPageOptions: itemsPerPageOptions}"
      :hide-default-footer="disablePagination"
      :dense="dense"
      hide-default-header
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column font-weight-bold', header.sortable ? 'sortable' : '', pagination.descending && header.value === pagination.sortBy ? 'desc active' : '', !pagination.descending && header.sortBy === pagination.sortBy ? 'asc active' : '', `text-${header.align}`]"
              @click="changeSort(header)"
            >
              <v-icon
                v-if="header.sortable && header.sortBy === pagination.sortBy && !pagination.descending"
                small
              >
                fas fa-arrow-up
              </v-icon>
              <v-icon
                v-else-if="header.sortable && header.sortBy === pagination.sortBy && pagination.descending"
                small
              >
                fas fa-arrow-down
              </v-icon>
              {{ header.text }}
            </th>
            <th
              v-if="actions"
              class="column font-weight-bold text-center"
            >
              {{ $t('actions') }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td
            v-for="(item_field, index) in tableFields"
            :key="index"
            :class="`text-${item_field.align}`"
          >
            <template v-if="item_field.edit">
              <v-badge color="primary">
                <span
                  v-if="item.callAttemp"
                  slot="badge"
                >
                  {{ item.callAttemp }}
                </span>
                <a @click="handleEdit(item)">
                  {{ getValueItem(item, item_field) | removeQuotes }}
                </a>
              </v-badge>
            </template>
            <template v-else-if="item_field.detail">
              <v-badge color="primary">
                <a @click="handleEdit(item)">
                  {{ $t('detail') }}
                </a>
              </v-badge>
            </template>
            <span v-else-if="!item_field.callback && !item_field.edit">
              {{ getValueItem(item, item_field) | removeQuotes }}
            </span>
            <span
              v-else-if="item_field.callback"
              v-html="callCallback(item_field, item)"
            />
          </td>
          <td
            v-if="actions"
            class="text-center"
          >
            <v-btn
              v-if="!item.deletedAt"
              :id="`btnEdit${item.id}`"
              small
              icon
              color="gray-color"
              @click="handleEdit(item)"
            >
              <v-icon medium>
                far fa-edit
              </v-icon>
            </v-btn>
            <v-btn
              v-if="!item.deletedAt"
              :id="`btnDelete${item.id}`"
              small
              icon
              color="gray-color"
              @click="handleDelete(item)"
            >
              <v-icon medium>
                far fa-trash-alt
              </v-icon>
            </v-btn>
            <v-btn
              v-if="item.status === 'Bloqueado'"
              :id="`btnToUnlock${item.id}`"
              small
              icon
              color="gray"
              @click="handleToUnlock(item)"
            >
              <v-icon small>
                fas fa-lock-open-alt
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <app-generic-table-elimination
      :row-data="rowInfo"
      :dialog="dialogElimination"
      :api-url="apiUrl"
      @delete="chageDelete"
    />
  </v-flex>
</template>

<script>

import handleErrorMixin from '../../../mixins/handleErrorMixin'
import AppGenericTableElimination from './AppGenericTableElimination'
import moment from 'moment'

/**
 * Component that represent a generic table page.
 *
 * @author Oscar Paredes
 * @version 1.0.0
 * @since 10/03/2020 10:30 AM
 */
export default {
  name: 'AppGenericTable',
  components: {
    AppGenericTableElimination
  },
  filters: {
    /**
     * Filter to remove quotes.
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    removeQuotes: (value) => {
      if (value && typeof value === 'string') {
        value = value.toString()
        return value.replace(/['"]+/g, '')
      }
      return value
    }
  },
  mixins: [handleErrorMixin],
  props: {
    actions: {
      type: Boolean,
      default: false,
      description: 'Representative custom table add column actions'
    },
    disablePagination: {
      type: Boolean,
      default: false,
      description: 'Representative validate disable pagination'
    },
    dense: {
      type: Boolean,
      default: false,
      description: 'Representative validate dense'
    },
    apiUrl: {
      description: 'Uri where you are going to make the request to bring back data',
      default: null,
      type: String
    },
    search: {
      type: Object,
      default: () => {},
      description: 'Representative custom table add column check action'
    },
    tableFields: {
      default: () => [],
      description: 'Data of the names of the columns to be displayed',
      type: Array
    },
    dataLoad: {
      default: () => [],
      description: '',
      type: Array
    },
    itemsPerPageOptions: {
      default: () => [20, 40, -1],
      description: '',
      type: Array
    },
    itemsPerPage: {
      default: 20,
      description: '',
      type: Number
    },
    disabledDefaultParams: {
      default: false,
      description: '',
      type: Boolean
    }
  },
  data () {
    return {
      allCheck: false,
      total: 0,
      data: [],
      dialogElimination: false,
      rowInfo: null,
      loading: true,
      pagination: {},
      selected: []
    }
  },
  computed: {
    /**
     * Method to get the columns for show or hide in the header table of the dymanic mode
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    headersLength () {
      let plusActions = 0
      if (this.actions) plusActions = 1
      return this.tableFields.length + plusActions
    }
  },
  watch: {
    pagination: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    dataLoad () {
      this.getDataFromApi()
    }
  },
  methods: {
    yesOrNotFormat (value) {
      return value ? 'Si' : 'No'
    },
    fileSizeFormat (value) {
      return value ? `${value} MB` : 'Sin tamaño'
    },
    getValueItem (item, itemField) {
      if (itemField.format && item[itemField.value] !== undefined && item[itemField.value] !== null) {
        return this[itemField.format](item[itemField.value])
      }
      return item[itemField.value]
    },
    /**
     * Method to call custom methods for each of the actions per row
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    callCallback (field, item) {
      return this[field.callback](item[field.value])
    },
    /**
     * Method to update variables after the elimination
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    chageDelete (dialog, success) {
      this.dialogElimination = dialog
      if (success && this.apiUrl) {
        this.rowInfo = null
        this.getDataFromApi()
      } else if (success) {
        this.$emit('delete-row', this.rowInfo)
      }
    },
    /**
     * Method that change from number to letter according to whether or note
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    changeNumberToLetter (row) {
      return row ? this.$t('yes') : this.$t('no')
    },
    /**
     * Method to order for each row if required
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    changeSort (header) {
      if (header.sortable) {
        if (this.pagination.sortBy === header.sortBy) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = header.sortBy
          this.pagination.descending = false
        }
      }
    },
    /**
     * Method to change format the date
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    dateFormat: function (value) {
      if (value === '0' || !value) return ''
      return moment.unix(value).format('YYYY-MM-DD hh:mm:ss')
    },
    /**
     * Method to load the back information
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    getDataFromApi () {
      this.loading = true
      if (this.apiUrl) {
        let params = !this.disabledDefaultParams ? { ...this.pagination, ...this.search } : this.search
        this.axios.get(this.apiUrl, { params: params })
          .then(response => {
            this.data = response.data.results || response.data
            this.total = response.data.totalItems || response.data.length
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.$_handleErrorMixin_generateError(error)
          })
      } else {
        this.data = this.dataLoad
        this.total = this.dataLoad.length
        this.loading = false
      }
    },
    /**
     * Method to delete row
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    handleDelete (rowData) {
      this.dialogElimination = true
      this.rowInfo = rowData
    },
    /**
     * Method to edit row
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 10/03/2020 10:30 AM
     */
    handleEdit (rowData) {
      this.$emit('edit-row', rowData)
    },
    /**
     * Method to unlock row
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 13/04/2020 11:30 AM
     */
    handleToUnlock (rowData) {
      this.$emit('to-unlock-row', rowData)
    },
    refreshDataTable () {
      this.getDataFromApi()
    }
  }
}
</script>

<i18n>
  {
    "es": {
      "actions": "Acciones",
      "action_download": "Descargar",
      "detail": "Ver Detalle",
      "no": "No",
      "yes": "Si"
    }
  }
</i18n>
