<template>
  <v-dialog
    v-model="dialog"
    max-width="30%"
    persistent
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('confirmation_delete.title') }}
      </v-card-title>
      <v-card-text>
        {{ $t('confirmation_delete.description') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          id="btnCancelDelete"
          color="quaternary"
          class="mb-2"
          @click="closeDialog"
        >
          {{ $t('confirmation_delete.cancel_button_text') }}
        </v-btn>
        <v-btn
          id="btnDelete"
          color="primary"
          class="mb-2"
          @click="deleteRow"
        >
          {{ $t('confirmation_delete.confirm_button_text') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import handleErrorMixin from '../../../mixins/handleErrorMixin'
import { SHOW_ALERT, ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST } from '../../../store/mutations-types'
/**
 * Component that represent a generic table elimination actions.
 *
 * @author Oscar Paredes
 * @since 20/02/2020 02:50 PM
 * @version 1.0.0
 */
export default {
  name: 'AppGenericTableElimination',
  mixins: [handleErrorMixin],
  props: {
    apiUrl: {
      description: 'Uri where you are going to make the request to bring back data',
      default: '',
      type: String
    },
    dialog: {
      type: Boolean,
      default: false,
      description: 'Representative model of the modal'
    },
    rowData: {
      description: 'Represents the rowData to be removed from the table',
      default: null,
      type: Object
    }
  },
  methods: {
    /**
       * Method that emits the closing of the modal
       *
       * @author Oscar Paredes
       * @since 20/02/2020 02:50 PM
       * @version 1.0.0
       */
    closeDialog () {
      this.$emit('delete', false, false)
    },
    /**
       * Method that eliminates the registration of a table
       *
       * @author Oscar Paredes
       * @since 20/02/2020 02:50 PM
       * @version 1.0.0
       */
    deleteRow () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      if (this.apiUrl) {
        this.axios.delete(`${this.apiUrl}/${this.rowData.id}`)
          .then(obj => {
            this.$emit('delete', false, true)
            this.$store.commit(SHOW_ALERT, {
              type: 'success',
              text: this.$t('delete_row'),
              show: true
            })
          })
          .catch(error => {
            this.$emit('delete', false, false)
            /* The backend API returned an uncontrolled error */
            this.$_handleErrorMixin_generateError(error)
          }).finally(() => {
            this.$store.commit(SUBTRACT_COUNTER_REQUEST)
          })
      } else {
        this.$emit('delete', false, true)
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      }
    }
  }
}
</script>
<i18n>
  {
    "es": {
      "confirmation_delete": {
        "cancel_button_text": "Cancelar",
        "confirm_button_text": "Confirmar",
        "description": "¿Desea eliminar el registro?",
        "title": "Confirmar eliminación"
      },
      "delete_row": "Registro eliminado correctamente"
    }
  }
</i18n>
